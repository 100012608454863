export const accountNavigation = [
  {
    name: "account.menu.accountOverviewHeader",
    path: "/account",
  },
  {
    name: "account.menu.yourProfile",
    path: "/account/profile",
  },
  {
    name: "account.menu.yourAddress",
    path: "/account/address",
  },
  {
    name: "account.menu.myPaymentsHeader",
    path: "/account/payment",
  },
  {
    name: "account.menu.orderHistoryHeader",
    path: "/account/order",
  },
  {
    name: "account.menu.vouchers",
    path: "/account/vouchers",
  },
  {
    name: "account.menu.newsletter",
    path: "/account/newsletter",
  },
  {
    name: "account.menu.logout",
  },
] as const;
